$(function() {
    let refreshCount = 0,
        csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");;
    const refreshAssetsStatus = function() {
        refreshCount++;
        const launch_screens = $("table[data-role=launch_screens_index] li[data-id]"),
            ids = launch_screens.map(
                function () {
                    return $(this).data("id");
                }
            ).toArray();
        $.ajax({
            type: "POST",
            headers: { "X-CSRF-Token": csrfToken },
            url: "/launch_screens/status",
            data: JSON.stringify({"ids": ids}),
            contentType: "application/json",
            error: function() { console.log("Refreshing launch screen assets status failed.") },
            success: function(data, status, xhr) {
                for (const id in data) {
                    let el = $("li[data-id="+ id + "]");
                    if (data[id]) {
                        $(el).removeClass("assets-failure alert-danger").addClass("assets-success alert-success");
                    } else {
                        $(el).removeClass("assets-success alert-success").addClass("assets-failure alert-danger");
                    }
                }
                // Stop refreshing after 5 minutes
                if (refreshCount < 60) setTimeout(refreshAssetsStatus, 5000);

            },
        });
    }

    if ($("table[data-role=launch_screens_index]").length > 0) setTimeout(refreshAssetsStatus, 5000);
})
