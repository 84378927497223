const setupPayloadInputValidation = () => {
  const isValidJSON = text => {
    try {
      JSON.parse(text);
      return true;
    } catch {
      return false;
    }
  };

  const setInputValid = (input, isValid) => {
    // Sanity check to see if it has valid JSON
    const label = input.labels[0];
    const submitButton = input.form.querySelector('[type="submit"]');

    if (isValid) {
      label.classList.remove('text-danger');
      submitButton.disabled = false;
    } else {
      label.classList.add('text-danger');
      submitButton.disabled = true;
    }
  };

  const handleInput = (event) => {
    setInputValid(event.target, isValidJSON(event.target.value));
  };

  $("#send_live_activity_message_form #payload").on("input", handleInput);
};

$(document).ready(function () {
  setupPayloadInputValidation();
});

