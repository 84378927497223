$(document).ready(function () {
  $("#sport_select").on("change",
    function () {
      $("#league_select").empty()
      $("#filter_form").submit()
    }
  )

  $("#league_select, #provider_select, #system_select").on("change",
    function () {
      $("#filter_form").submit()
    }
  )
})
