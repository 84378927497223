// Docs: https://github.com/caroso1222/notyf
import { Notyf } from "notyf";
import "notyf/notyf.min.css";

document.addEventListener("DOMContentLoaded", function (event) {
  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
    duration: 5000,
    types: [
      {
        type: "error",
        className: "notyf-sa-custom",
        // duration 0 and dismissable are set here so that the user can inspect
        // the error message before dismissing it whereas we have a
        // 5000ms delay before closing success messages
        duration: 0,
        dismissible: true,
      },
    ],
  });
  window.notyf = notyf;
});
